import { MouseEvent, useContext, useEffect } from "react";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { getOutbox, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";
import { EventCell } from "./EventCell";
import { Event } from "../Models/Event";
import { useHistory } from "react-router";
import { SentEvent } from "../Models/SentEvent";

export function Outbox() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    refreshEvents();
  }, []);

  const refreshEvents = async () => {
    const response = await getOutbox(state);
    const handler = new DispatchedResultHandler<SentEvent[]>(
      dispatch,
      (events) => {
        dispatch({ type: "set_outbox", events: events });
      }
    );
    handleResult(response, handler);
  };

  const handleClickRefresh = async (e: MouseEvent) => {
    e.preventDefault();
    refreshEvents();
  };

  const handleClickDetails = async (e: MouseEvent, event: Event) => {
    e.preventDefault();
    history.push("/outbox/" + event.id);
  };

  function hasEvents() {
    if (state.outbox) {
      return state.outbox.length > 0;
    } else {
      return false;
    }
  }

  const content = () => {
    if (hasEvents()) {
      return (
        <ul className="cell-list">
          {state.outbox.map((element) => {
            return (
              <li
                key={element.id}
                onClick={(e) => {
                  handleClickDetails(e, element);
                }}
              >
                <EventCell
                  key={element.id}
                  event={element}
                  isTemplate={false}
                  isInbox={false}
                />
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <p>Looks like you outbox is empty. Send out some event invites!</p>
      );
    }
  };

  return (
    <div className="page">
      <h1>Outbox</h1>
      <button onClick={handleClickRefresh}>Refresh</button>
      {content()}
    </div>
  );
}
