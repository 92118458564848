import { MouseEvent, useContext, useEffect } from "react";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { getEvents, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";
import { Event } from "../Models/Event";
import { useHistory } from "react-router";
import { HorizontalContainer, Spacer } from "./LayoutComponents";
import { EventList } from "./EventList";

export function EventsPage() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();

  const refreshEvents = async () => {
    const response = await getEvents(state);
    const handler = new DispatchedResultHandler<Event[]>(dispatch, (events) => {
      dispatch({ type: "set_events", events: events });
    });
    handleResult(response, handler);
  };

  const handleRefresh = async (e: MouseEvent) => {
    e.preventDefault();
    refreshEvents();
  };

  const handleAdd = async (e: MouseEvent) => {
    e.preventDefault();
    history.push("/events/add");
  };

  const handleDetails = async (event: Event) => {
    history.push("/events/" + event.id);
  };

  useEffect(() => {
    refreshEvents();
  }, []);

  function hasEvents() {
    if (state.events) {
      return state.events.length > 0;
    } else {
      return false;
    }
  }

  function onHorn(event: Event) {
    history.push("/send/" + event.id);
  }

  const content = () => {
    if (hasEvents()) {
      return (
        <EventList
          events={state.events}
          onSelect={handleDetails}
          onHorn={onHorn}
        />
      );
    } else {
      return <p>Looks like you don't have any events</p>;
    }
  };

  return (
    <div className="page">
      <HorizontalContainer>
        <h1>Events</h1>
        <Spacer />
        <button onClick={handleAdd}>Add</button>
      </HorizontalContainer>
      <button onClick={handleRefresh}>Refresh</button>
      {content()}
    </div>
  );
}
