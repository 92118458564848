import { MouseEvent, useContext, useEffect } from "react";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { getInbox, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";
import { EventCell } from "./EventCell";
import { Event } from "../Models/Event";
import { useHistory } from "react-router";
import { SentEvent } from "../Models/SentEvent";

export function Inbox() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    refreshEvents();
  }, []);

  const refreshEvents = async () => {
    const response = await getInbox(state);
    const handler = new DispatchedResultHandler<SentEvent[]>(
      dispatch,
      (events) => {
        dispatch({ type: "set_inbox", events: events });
      }
    );
    handleResult(response, handler);
  };

  const handleClickRefresh = async (e: MouseEvent) => {
    e.preventDefault();
    refreshEvents();
  };

  const handleClickDetails = async (e: MouseEvent, event: Event) => {
    e.preventDefault();
    history.push("/inbox/" + event.id);
  };

  function hasEvents() {
    if (state.inbox) {
      return state.inbox.length > 0;
    } else {
      return false;
    }
  }

  const content = () => {
    if (hasEvents()) {
      return (
        <ul className="cell-list">
          {state.inbox.map((element) => {
            return (
              <li
                key={element.id}
                onClick={(e) => {
                  handleClickDetails(e, element);
                }}
              >
                <EventCell event={element} isTemplate={false} isInbox={true} />
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <p>Looks like you inbox is empty</p>;
    }
  };

  return (
    <div className="page">
      <h1>Inbox</h1>
      <button onClick={handleClickRefresh}>Refresh</button>
      {content()}
    </div>
  );
}
