import { useContext, useEffect } from "react";
import { Friend } from "../Models/Friend";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { getFriends, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";
import { Cell, HorizontalContainer, Spacer } from "./LayoutComponents";

interface FriendsListProps {
  selected?: Friend[];
  didSelect: (friend: Friend) => void;
}

interface FriendCellProps {
  friend: Friend;
  selected: boolean;
}

function FriendSelectionCell({ friend, selected }: FriendCellProps) {
  return (
    <Cell clickable={true}>
      <HorizontalContainer>
        <h5>{friend.name}</h5>
        <Spacer />
        {selected ? <h5>&#10003;</h5> : null}
      </HorizontalContainer>
    </Cell>
  );
}

export function FriendsList({ selected, didSelect }: FriendsListProps) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    refreshFriends();
  }, []);

  const refreshFriends = async () => {
    const response = await getFriends(state);
    const handler = new DispatchedResultHandler<Friend[]>(
      dispatch,
      (friends) => {
        dispatch({ type: "set_friends", friends: friends });
      }
    );
    handleResult(response, handler);
  };

  function isSelected(friend: Friend) {
    return selected?.find((f) => f.id === friend.id) !== undefined;
  }

  return (
    <div className="friends-list">
      <ul className="cell-list">
        {state.friends.map((friend) => {
          return (
            <li
              onClick={() => didSelect(friend)}
              key={friend.id + selected?.toString()}
            >
              <FriendSelectionCell
                friend={friend}
                selected={isSelected(friend)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
