import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Friend } from "../Models/Friend";
import { addFriend, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";
import { Modal } from "./Modal";

interface AddFriendPageParams {
  code?: string;
}

export function AddFriendPage() {
  const { state } = useContext(Context);
  const { code } = useParams<AddFriendPageParams>();
  const [enteredCode, setEnteredCode] = useState<string>();
  const [showingModal, setShowingModal] = useState<boolean>(false);

  const failedMessage = "A friend with that code was not added successfully.";
  const [modalMessage, setModalMessage] = useState<string>(failedMessage);

  useEffect(() => {
    console.log("code: " + code);
    setEnteredCode(code ?? "");
  }, []);

  function resultModal(friend?: Friend) {
    if (friend) {
      setModalMessage("Successfully added " + friend.name + "!");
    } else {
      setModalMessage(failedMessage);
    }
    setShowingModal(true);
  }

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (enteredCode != undefined) {
      let result = await addFriend(state, enteredCode);
      handleResult(result, {
        onSuccess: (newFriend) => {
          setEnteredCode("");
          resultModal(newFriend);
        },
        onFailure: (error) => {
          console.log(error);

          resultModal();
        },
      });
    } else {
      resultModal();
    }
  };

  return (
    <div className="page">
      <h1>Add Friend</h1>
      <label>Add via friend code</label>
      <input
        type="text"
        value={code}
        onChange={(event) => setEnteredCode(event.target.value)}
      />
      <button
        onClick={handleSubmit}
        disabled={!enteredCode || enteredCode.length === 0}
      >
        Add
      </button>

      <Modal isShowing={showingModal} setShowing={setShowingModal}>
        <h2>{modalMessage}</h2>
      </Modal>
    </div>
  );
}
