import { createContext, ReactNode, useReducer } from "react";
import { Action, Reducer } from "./Reducer";
import { EmptyState, State } from "./State";

export const Context = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({ state: EmptyState(), dispatch: () => null });

interface Props {
  children: ReactNode;
}

export function Store(props: Props) {
  const [state, dispatch] = useReducer(Reducer, EmptyState());

  return (
    <Context.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </Context.Provider>
  );
}
