import { ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
}

export function VerticalContainer({ children }: ContainerProps) {
  return <div className="vertical-container">{children}</div>;
}

export function HorizontalContainer({ children }: ContainerProps) {
  return <div className="horizontal-container">{children}</div>;
}

export function Spacer() {
  return <div className="container-spacer" />;
}

interface CellProps extends ContainerProps {
  clickable?: boolean;
}

export function Cell({ children, clickable = false }: CellProps) {
  let style = "cell" + (clickable === true ? " cell-clickable" : "");
  return <div className={style}>{children}</div>;
}
