import { MouseEvent, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { User } from "../Models/User";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { getProfile, handleResult } from "../Utility/Services";
import { Context } from "../Utility/Store";

export function Profile() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await getProfile(state);
      const handler = new DispatchedResultHandler<User>(dispatch, (user) => {
        dispatch({ type: "set_user", user: user });
      });
      return handleResult(response, handler);
    };
    fetchProfile();
  }, []);

  const handleLogout = async (e: MouseEvent) => {
    e.preventDefault();
    dispatch({ type: "logout" });
    history.push("/");
  };

  const handleAddFriend = async (e: MouseEvent) => {
    e.preventDefault();
    history.push("/friends/add/");
  };

  const content = () => {
    if (state.user) {
      return (
        <div className="page">
          <h1>{state.user.name}</h1>
          <h3>Friend Code</h3>
          <p>{state.user.friendCode}</p>
          <button className="line-item" onClick={handleAddFriend}>
            Add Friend
          </button>
          <button className="line-item" onClick={handleLogout}>
            Logout
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <p>Hmmm, nothing to see here</p>
        </div>
      );
    }
  };

  return content();
}
