import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { Friend } from "../Models/Friend";
import { Event } from "../Models/Event";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "./Modal";
import { FriendsList } from "./FriendsList";
import { HorizontalContainer, Spacer } from "./LayoutComponents";
import {
  createEvent,
  handleResult,
  Result,
  sendEvent,
  updateEvent,
} from "../Utility/Services";
import { Context } from "../Utility/Store";
import { transportDate } from "../Utility/Utilities";

interface EditEventProps {
  event?: Event;
  sending?: boolean;
  onSave: () => void;
}

export function EditEvent({ event, sending = false, onSave }: EditEventProps) {
  const { state } = useContext(Context);

  var eventDate = event?.date;
  if (eventDate) {
    eventDate = new Date(eventDate);
  }

  function stateValue<T>(edit: T, create: T, send: T) {
    if (sending) {
      return send;
    } else if (event) {
      return edit;
    } else {
      return create;
    }
  }

  // Event Data
  const [friends, setFriends] = useState<Friend[]>(event?.recipients ?? []);
  const [name, setName] = useState<string>(event?.name ?? "");
  const [description, setDescription] = useState<string>(
    event?.description ?? ""
  );
  const [date, setDate] = useState<Date | null>(eventDate ?? new Date());

  // Presentation Logic
  const title = stateValue("Edit Event", "New Event", "Sound the Horn!");

  const [showingFriends, setShowingFriends] = useState<boolean>(false);

  const handleDate = (date: Date | null) => {
    setDate(date);
  };

  const selectFriends = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowingFriends(true);
  };

  const addFriend = (friend: Friend) => {
    var updatedFriends = [...friends];
    let currentIndex = updatedFriends.indexOf(friend);
    if (currentIndex !== -1) {
      updatedFriends.splice(currentIndex, 1);
    } else {
      updatedFriends.push(friend);
    }
    setFriends(updatedFriends);
  };

  const handleSave = async (e: React.MouseEvent) => {
    e.preventDefault();
    const recipientIDs = friends.map((f) => {
      return f.id;
    });
    var dateString = transportDate(date ?? new Date());
    var result: Result<Event>;
    if (sending) {
      result = await sendEvent(state, {
        name: name,
        description: description,
        date: dateString,
        recipientIDs: recipientIDs,
      });
    } else if (event) {
      result = await updateEvent(state, {
        id: event.id,
        name: name,
        description: description,
        date: dateString,
        recipientIDs: recipientIDs,
      });
    } else {
      result = await createEvent(state, {
        name: name,
        description: description,
        date: dateString,
        recipientIDs: recipientIDs,
      });
    }

    handleResult(result, {
      onSuccess: () => {
        onSave();
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  const timeLabel = sending ? "Date" : "Time";

  const datePicker = () => {
    if (sending) {
      return (
        <DatePicker
          selected={date}
          onChange={handleDate}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="MM/dd/yyyy h:mm aa"
        />
      );
    } else {
      return (
        <DatePicker
          selected={date}
          onChange={handleDate}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
      );
    }
  };

  return (
    <div>
      <HorizontalContainer>
        <h1>{title}</h1>
        <Spacer />
        <button onClick={handleSave}>Save</button>
      </HorizontalContainer>
      <form>
        <label>Name</label>
        <input
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <label>Description</label>
        <input
          type="text"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <label>{timeLabel}</label>
        <div>{datePicker()}</div>
        <HorizontalContainer>
          <label>Friends</label>
          <Spacer />
          <button onClick={selectFriends}>Add Friend</button>
        </HorizontalContainer>
        <ul className="cell-list">
          {friends.map((friend) => {
            return (
              <li key={friend.id}>
                <p>{friend.name}</p>
              </li>
            );
          })}
        </ul>
      </form>

      <Modal isShowing={showingFriends} setShowing={setShowingFriends}>
        <FriendsList selected={friends} didSelect={addFriend} />
      </Modal>
    </div>
  );
}
