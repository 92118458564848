import { SentEvent } from "../Models/SentEvent";
import displayDate from "../Utility/DisplayDate";
import { ResponseField } from "./ResponseField";
import { ResponseList } from "./ResponseList";

export interface EventCellProps {
  event: SentEvent;
  isInbox: boolean;
}

export function SentEventDetails({ event, isInbox }: EventCellProps) {
  const hostContent = () => {
    if (isInbox) {
      return <p>Hosted by {event.host.name}</p>;
    }
  };

  const responseField = () => {
    return isInbox ? (
      <ResponseField event={event} response={event.userResponse} />
    ) : (
      <></>
    );
  };

  return (
    <div>
      <h1>{event.name}</h1>
      <p>{displayDate(event.date)}</p>
      <p>{event.description}</p>
      {hostContent()}
      {responseField()}
      <h3>Recipients</h3>
      <ResponseList recipients={event.recipients} responses={event.responses} />
    </div>
  );
}
