import { Context } from "../Utility/Store";
import { Link } from "react-router-dom";
import { useContext } from "react";

export function NavBar() {
  const { state } = useContext(Context);

  const authedLinks = () => {
    return (
      <div>
        <li className="nav-item nav-horn-item">
          <Link to="/send">
            <img
              className="nav-horn"
              src={process.env.PUBLIC_URL + "/Icon.png"}
            />
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/events">Events</Link>
        </li>
        <li className="nav-item">
          <Link to="/inbox">Inbox</Link>
        </li>
        <li className="nav-item">
          <Link to="/outbox">Outbox</Link>
        </li>
        <li className="nav-item nav-item-right">
          <Link to="/profile">Profile</Link>
        </li>
      </div>
    );
  };

  const unauthedLinks = () => {
    return (
      <div>
        <li className="nav-item">
          <Link to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/register">Get Started</Link>
        </li>
        <li className="nav-item nav-item-right">
          <Link to="/login">Login</Link>
        </li>
      </div>
    );
  };

  return (
    <div>
      <ul className="nav-bar">
        {state.authToken ? authedLinks() : unauthedLinks()}
      </ul>
    </div>
  );
}
