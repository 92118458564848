import { Friend } from "../Models/Friend";
import { Response } from "../Models/Response";
import { ResponseCell } from "./ResponseCell";

interface ResponseListProps {
  recipients: Friend[];
  responses: Response[];
}

export function ResponseList({ recipients, responses }: ResponseListProps) {
  function response(friend: Friend) {
    return responses.find((r) => r.userId === friend.id);
  }

  return (
    <ul className="cell-list">
      {recipients.map((friend) => {
        return (
          <li key={friend.id}>
            <ResponseCell friend={friend} response={response(friend)} />
          </li>
        );
      })}
    </ul>
  );
}
