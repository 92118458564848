import { useContext, useEffect, useState } from "react";
import { Context } from "../Utility/Store";

import { useParams } from "react-router";
import { SentEventDetails } from "./SentEventDetails";
import { SentEvent } from "../Models/SentEvent";
import { getSentEventDetails, handleResult } from "../Utility/Services";
import { DispatchedResultHandler } from "../Utility/Reducer";

interface SendEventDetailsProps {
  isInbox: boolean;
}

interface SentEventDetailsParams {
  eventId?: string;
}

export function SentEventDetailsPage({ isInbox }: SendEventDetailsProps) {
  const { state, dispatch } = useContext(Context);
  const { eventId } = useParams<SentEventDetailsParams>();
  const [event, setEvent] = useState<SentEvent>();

  useEffect(() => {
    async function fetch(eventId: string) {
      const result = await getSentEventDetails(state, eventId);
      const handler = new DispatchedResultHandler<SentEvent>(
        dispatch,
        (event) => {
          setEvent(event);
        }
      );
      handleResult(result, handler);
    }
    if (eventId) {
      fetch(eventId);
    }
  }, []);

  const content = () => {
    if (event) {
      return (
        <SentEventDetails key={event?.id} event={event} isInbox={isInbox} />
      );
    } else {
      return <h3>No event found.</h3>;
    }
  };

  return <div className="page">{content()}</div>;
}
