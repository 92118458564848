import { AuthToken, ClearToken, SetToken } from "../Models/AuthToken";
import { Friend } from "../Models/Friend";
import { Event } from "../Models/Event";
import { EmptyState, State } from "./State";
import { NetworkError, ResultHandler } from "./Services";
import { User } from "../Models/User";
import { SentEvent } from "../Models/SentEvent";

export type Action =
  | { type: "set_events"; events: Event[] }
  | { type: "set_inbox"; events: SentEvent[] }
  | { type: "set_outbox"; events: SentEvent[] }
  | { type: "set_token"; token: AuthToken | null }
  | { type: "set_friends"; friends: Friend[] }
  | { type: "set_user"; user: User }
  | { type: "update_inbox_event"; event: SentEvent }
  | { type: "logout" }
  | { type: "handle_error"; error: NetworkError };

export function Reducer(state: State, action: Action): State {
  switch (action.type) {
    case "set_events":
      return { ...state, events: action.events };
    case "set_inbox":
      return { ...state, inbox: action.events };
    case "set_outbox":
      return { ...state, outbox: action.events };
    case "set_friends":
      return { ...state, friends: action.friends };
    case "set_token":
      if (action.token) {
        SetToken(action.token);
        return { ...state, authToken: action.token };
      } else {
        return Reducer(state, { type: "logout" });
      }
    case "set_user":
      return { ...state, user: action.user };
    case "update_inbox_event":
      var inbox = state.inbox;
      const index = inbox.findIndex((e) => e.id === action.event.id);
      inbox.splice(index, 1, action.event);
      return { ...state, inbox: inbox };
    case `logout`:
      ClearToken();
      return EmptyState();
    case "handle_error":
      return Reducer(state, { type: "logout" });
    default:
      return state;
  }
}

export class DispatchedResultHandler<T> implements ResultHandler<T> {
  onSuccess: (data: T) => void;
  onFailure: (error: NetworkError) => void;

  dispatch: React.Dispatch<Action>;

  constructor(
    dispatch: React.Dispatch<Action>,
    onSuccess: (data: T) => void,
    onFailure: (error: NetworkError) => void = (error) => {}
  ) {
    this.onSuccess = onSuccess;
    this.dispatch = dispatch;
    this.onFailure = (error) => {
      onFailure(error);
      console.log("Error: " + error.reason);
      dispatch({ type: "handle_error", error: error });
    };
  }
}
