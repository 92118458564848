import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Inbox } from "./Components/Inbox";
import { Landing } from "./Components/Landing";
import { Store } from "./Utility/Store";
import { NavBar } from "./Components/NavBar";
import { Profile } from "./Components/Profile";
import { AddEventPage } from "./Components/AddEventPage";
import { Outbox } from "./Components/Outbox";
import { EventDetailsPage } from "./Components/EventDetailsPage";
import { HornPage } from "./Components/HornPage";
import { SentEventDetailsPage } from "./Components/SentEventDetailsPage";
import { EventsPage } from "./Components/EventsPage";
import { LoginPage } from "./Components/LoginPage";
import { RegisterPage } from "./Components/RegisterPage";
import { AddFriendPage } from "./Components/AddFriendPage";
import { AuthenticatedRoute } from "./Components/AuthenticatedRoute";

function App() {
  return (
    <Store>
      <Router>
        <div>
          <NavBar />
          <Switch>
            {/* Public Routes */}
            <Route exact path="/">
              <Landing />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>

            {/* Authenticated Routes */}
            <AuthenticatedRoute exact path="/events">
              <EventsPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/events/add">
              <AddEventPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/events/:eventId">
              <EventDetailsPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/send">
              <HornPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/send/:eventId">
              <HornPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/inbox">
              <Inbox />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/outbox">
              <Outbox />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/inbox/:eventId">
              <SentEventDetailsPage isInbox={true} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/outbox/:eventId">
              <SentEventDetailsPage isInbox={false} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/profile">
              <Profile />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/friends/add/">
              <AddFriendPage />
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/friends/add/:code">
              <AddFriendPage />
            </AuthenticatedRoute>
          </Switch>
        </div>
      </Router>
    </Store>
  );
}

export default App;
