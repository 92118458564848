import { useContext } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { Context } from "../Utility/Store";

export function AuthenticatedRoute({ children, ...rest }: RouteProps) {
  const { state } = useContext(Context);
  const location = useLocation();

  const element =
    state.authToken?.value && state.authToken.value.length > 0 ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location.pathname },
        }}
      />
    );

  return <Route {...rest}>{element}</Route>;
}
