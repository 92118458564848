import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AuthToken } from "../Models/AuthToken";
import { DispatchedResultHandler } from "../Utility/Reducer";
import { handleResult, login, signUp } from "../Utility/Services";
import { Context } from "../Utility/Store";

export interface AuthenticationProps {
  newUser: boolean;
}

interface LocationState {
  from?: string;
}

export function Authentication({ newUser }: AuthenticationProps) {
  const { dispatch } = React.useContext(Context);
  const history = useHistory();
  const [isSignup, setIsSignup] = useState<boolean>(newUser);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [error, setError] = useState<string>("");

  const location = useLocation<LocationState>();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = isSignup
      ? await signUp({
          name: name,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        })
      : await login({
          email: email ?? "",
          password: password ?? "",
        });

    const handler = new DispatchedResultHandler<AuthToken>(
      dispatch,
      (token) => {
        dispatch({ type: "set_token", token: token });
        history.push(location.state?.from || "/events");
      },
      (error) => {
        setError(error.reason);
      }
    );
    handleResult(result, handler);
  };

  const formContent = () => {
    if (isSignup) {
      return (
        <div>
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>
          <label>
            Confirm Password:
            <input
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </label>
        </div>
      );
    } else {
      return (
        <div>
          <label>
            Email:
            <input
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>
        </div>
      );
    }
  };

  return (
    <div>
      <h1>{isSignup ? "Sign Up" : "Log In"}</h1>
      <button onClick={(event) => setIsSignup(!isSignup)}>
        {isSignup ? "Switch to Log In" : "Switch to Sign Up"}
      </button>
      <form onSubmit={handleSubmit}>
        {formContent()}
        <input type="submit" value={isSignup ? "Sign Up" : "Log In"} />
      </form>
      <p>{error}</p>
    </div>
  );
}
