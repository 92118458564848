import { useContext, useEffect, useState } from "react";
import { Context } from "../Utility/Store";
import { Event } from "../Models/Event";

import { EditEvent } from "./EditEvent";
import { useHistory, useParams } from "react-router";

interface SendEventParams {
  eventId?: string;
}

export function HornPage() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const { eventId } = useParams<SendEventParams>();
  const [event, setEvent] = useState<Event>();

  useEffect(() => {
    if (eventId) {
      const existingEvent = state.events.find((e) => e.id === eventId);
      setEvent(existingEvent);
    }
  }, []);

  const handleSave = () => {
    history.push("/outbox");
  };

  return (
    <div className="page">
      <EditEvent
        key={event?.id}
        event={event}
        sending={true}
        onSave={handleSave}
      />
    </div>
  );
}
