import { MouseEvent } from "react";
import { Event } from "../Models/Event";
import displayDate from "../Utility/DisplayDate";
import { Cell, HorizontalContainer, Spacer } from "./LayoutComponents";

export interface EventCellProps {
  event: Event;
  isTemplate: boolean;
  isInbox: boolean;
  onHorn?: (event: Event) => void;
}

export function EventCell({
  event,
  isTemplate,
  isInbox,
  onHorn,
}: EventCellProps) {
  const handleHorn = async (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onHorn?.(event);
  };

  const hostContent = () => {
    if (isInbox) {
      return <p>Invited by {event.host.name}</p>;
    }
  };

  const hornButton = () => {
    if (isTemplate) {
      return (
        <button className={"event-horn"} onClick={handleHorn}>
          Sound the horn!
        </button>
      );
    }
  };

  return (
    <Cell clickable={true}>
      <HorizontalContainer>
        <div>
          <h1>{event.name}</h1>
          <p>{displayDate(event.date)}</p>
          {hostContent()}
        </div>
        <Spacer />
        {hornButton()}
      </HorizontalContainer>
    </Cell>
  );
}
