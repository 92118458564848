import React from "react";
import { EventCell } from "./EventCell";
import { Event } from "../Models/Event";

interface EventListProps {
  events: Event[];
  onSelect: (event: Event) => void;
  onHorn?: (event: Event) => void;
}

export function EventList({ events, onSelect, onHorn }: EventListProps) {
  const handleClickDetails = async (e: React.MouseEvent, event: Event) => {
    e.preventDefault();
    onSelect(event);
  };

  return (
    <ul className="cell-list">
      {events.map((element) => {
        return (
          <li
            key={element.id}
            onClick={(e) => {
              handleClickDetails(e, element);
            }}
          >
            <EventCell
              event={element}
              isTemplate={true}
              isInbox={false}
              onHorn={onHorn}
            />
          </li>
        );
      })}
    </ul>
  );
}
