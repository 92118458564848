export interface AuthToken {
  value: string;
}

const TokenKey = "auth_token";

export function SetToken(token: AuthToken) {
  localStorage.setItem(TokenKey, token.value);
}

export function DefaultToken() {
  var value = localStorage.getItem(TokenKey);
  if (value) {
    return { value: value };
  } else {
    return null;
  }
}

export function ClearToken() {
  localStorage.removeItem(TokenKey);
}
