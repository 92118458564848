import { AuthToken } from "../Models/AuthToken";
import { Credentials } from "../Models/Credentials";
import { Event } from "../Models/Event";
import { SentEvent } from "../Models/SentEvent";
import { Friend } from "../Models/Friend";
import { User } from "../Models/User";
import { State } from "./State";
import { Attending } from "../Models/Attending";
import { Response as ModelResponse } from "../Models/Response";

// Utility Members
const host = "https://api.flugelhorn.io";
// const host = 'http://127.0.0.1:8080'

export enum ResultType {
  success,
  failure,
}
export type Result<T> =
  | { type: ResultType.success; value: T }
  | { type: ResultType.failure; error: NetworkError };
export interface NetworkError {
  code: number;
  reason: string;
}

export interface ResultHandler<T> {
  onSuccess: (data: T) => void;
  onFailure: (error: NetworkError) => void;
}

export function handleResult<T>(result: Result<T>, handler: ResultHandler<T>) {
  if (result.type === ResultType.success) {
    handler.onSuccess(result.value);
  } else {
    handler.onFailure(result.error);
  }
}

async function handleError<T>(response: Response): Promise<Result<T>> {
  const data = await response.json();
  if (response.ok) {
    return { type: ResultType.success, value: data };
  } else {
    return {
      type: ResultType.failure,
      error: { code: response.status, reason: data.reason },
    };
  }
}

function defaultHeaders(token: AuthToken | null): Headers {
  const result = new Headers();
  result.set("Content-Type", "application/json");
  if (token != null) {
    result.set(`Authorization`, `Bearer ` + token.value);
  }
  return result;
}

async function get<T>(
  path: String,
  token: AuthToken | null,
  headers: Headers | null = null
): Promise<Result<T>> {
  let actualHeaders = headers ?? defaultHeaders(token);
  const options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: actualHeaders,
  };
  const url = [host, path].join("/");
  const response = await fetch(url, options);
  return handleError(response);
}

async function post<T>(
  path: String,
  token: AuthToken | null,
  headers: Headers | null = null,
  body: any | null = null
): Promise<Result<T>> {
  let actualHeaders = headers ?? defaultHeaders(token);
  const options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: actualHeaders,
    body: JSON.stringify(body),
  };
  const url = [host, path].join("/");
  const response = await fetch(url, options);
  return handleError(response);
}

async function put<T>(
  path: String,
  token: AuthToken | null,
  headers: Headers | null = null,
  body: any | null = null
): Promise<Result<T>> {
  let actualHeaders = headers ?? defaultHeaders(token);
  const options: RequestInit = {
    method: "PUT",
    mode: "cors",
    headers: actualHeaders,
    body: JSON.stringify(body),
  };
  const url = [host, path].join("/");
  const response = await fetch(url, options);
  return handleError(response);
}

export async function getEvents(state: State): Promise<Result<Event[]>> {
  return await get("events", state.authToken);
}

export async function login(
  credentials: Credentials
): Promise<Result<AuthToken>> {
  let headers = defaultHeaders(null);
  const token = credentials.email + ":" + credentials.password;
  headers.set("Authorization", "Basic " + btoa(token));
  return await post("users/login/", null, headers);
}

export interface CreateUserRequest {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface CreateEventRequest {
  name: string;
  description: string;
  date: string;
  recipientIDs: string[];
}

export interface UpdateEventRequest {
  id: string;
  name: string;
  description: string;
  date: string;
  recipientIDs: string[];
}

export interface SendEventRequest {
  name: string;
  description: string;
  date: string;
  recipientIDs: string[];
}

export interface SendResponseRequest {
  attending: Attending;
  comment: string;
}

export interface UpdateResponseRequest {
  attending: Attending;
  comment: string;
}

export interface AddFriendRequest {
  code: string;
}

export async function signUp(
  createRequest: CreateUserRequest
): Promise<Result<AuthToken>> {
  let headers = defaultHeaders(null);
  return await post("users/register/", null, headers, createRequest);
}

export async function getInbox(state: State): Promise<Result<SentEvent[]>> {
  return await get("inbox", state.authToken);
}

export async function getOutbox(state: State): Promise<Result<SentEvent[]>> {
  return await get("outbox", state.authToken);
}

export async function getProfile(state: State): Promise<Result<User>> {
  return await get("profile", state.authToken);
}

export async function getFriends(state: State): Promise<Result<Friend[]>> {
  return await get("friends", state.authToken);
}

export async function createEvent(
  state: State,
  createRequest: CreateEventRequest
): Promise<Result<Event>> {
  return await post("events", state.authToken, null, createRequest);
}

export async function updateEvent(
  state: State,
  updateRequest: UpdateEventRequest
): Promise<Result<Event>> {
  return await put(
    "events/" + updateRequest.id,
    state.authToken,
    null,
    updateRequest
  );
}

export async function sendEvent(
  state: State,
  sendRequest: SendEventRequest
): Promise<Result<Event>> {
  return await post("horn", state.authToken, null, sendRequest);
}

export async function sendResponse(
  state: State,
  event: SentEvent,
  sendRequest: SendResponseRequest
): Promise<Result<SentEvent>> {
  return await post(
    "events/" + event.id + "/responses",
    state.authToken,
    null,
    sendRequest
  );
}

export async function updateResponse(
  state: State,
  event: SentEvent,
  response: ModelResponse,
  updateRequest: UpdateResponseRequest
): Promise<Result<SentEvent>> {
  return await put(
    "events/" + event.id + "/responses/" + response.id,
    state.authToken,
    null,
    updateRequest
  );
}

export async function getSentEventDetails(
  state: State,
  eventId: string
): Promise<Result<SentEvent>> {
  return await get("sent/" + eventId, state.authToken);
}

export async function getEventDetails(
  state: State,
  eventId: string
): Promise<Result<Event>> {
  return await get("events/" + eventId, state.authToken);
}

export async function addFriend(
  state: State,
  code: string
): Promise<Result<Friend>> {
  let body: AddFriendRequest = { code: code };
  return await post("friends/", state.authToken, null, body);
}
