import { MouseEvent, useContext, useState } from "react";
import { Attending } from "../Models/Attending";
import { Response } from "../Models/Response";
import { HorizontalContainer } from "./LayoutComponents";
import {
  handleResult,
  Result,
  sendResponse,
  updateResponse,
} from "../Utility/Services";
import { SentEvent } from "../Models/SentEvent";
import { Context } from "../Utility/Store";

interface ResponseFieldProps {
  response?: Response;
  event: SentEvent;
}

export function ResponseField({ response, event }: ResponseFieldProps) {
  const { state, dispatch } = useContext(Context);
  const [comment, setComment] = useState<string>(response?.comment ?? "");
  const [attending, setAttending] = useState<Attending>(
    response?.attending ?? Attending.MAYBE
  );

  const isSelected = (attendingCase: Attending) => {
    return attending === attendingCase;
  };

  const buttonClassName = (attending: Attending) => {
    var className = "response-button";
    return isSelected(attending) ? className + " response-selected" : className;
  };

  const attendingDisplayName = (attending: Attending) => {
    switch (attending) {
      case Attending.YES:
        return "Yes";
      case Attending.NO:
        return "No";
      case Attending.MAYBE:
        return "Maybe";
    }
  };

  const attendingButton = (attending: Attending) => {
    return (
      <button
        className={buttonClassName(attending)}
        onClick={(e) => {
          e.preventDefault();
          setAttending(attending);
        }}
      >
        {attendingDisplayName(attending)}
      </button>
    );
  };

  const submitTitle = response ? "Update Response" : "Send Response";
  const onSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    var result: Result<SentEvent>;
    if (response) {
      result = await updateResponse(state, event, response, {
        attending: attending,
        comment: comment,
      });
    } else {
      result = await sendResponse(state, event, {
        attending: attending,
        comment: comment,
      });
    }

    handleResult(result, {
      onSuccess: (event) => {
        dispatch({ type: "update_inbox_event", event: event });
        window.location.reload();
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <div>
      <h3>Attending?</h3>
      <HorizontalContainer>
        {attendingButton(Attending.YES)}
        {attendingButton(Attending.NO)}
        {attendingButton(Attending.MAYBE)}
      </HorizontalContainer>
      <label>Comment</label>
      <input
        type="text"
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
      <button onClick={onSubmit}>{submitTitle}</button>
    </div>
  );
}
