import { Friend } from "../Models/Friend";
import { Response } from "../Models/Response";
import { capitalize } from "../Utility/Utilities";
import { Cell, HorizontalContainer, Spacer } from "./LayoutComponents";

interface ResponseCellProps {
  friend: Friend;
  response?: Response;
}

export function ResponseCell({ friend, response }: ResponseCellProps) {
  const attending = () => {
    if (response) {
      return <p>{capitalize(response.attending)}</p>;
    } else {
      return <></>;
    }
  };

  const comment = () => {
    if (response) {
      return <p className="response-comment">{response.comment}</p>;
    } else {
      return <></>;
    }
  };

  return (
    <Cell>
      <div>
        <HorizontalContainer>
          <p>{friend.name}</p>
          <Spacer />
          {attending()}
        </HorizontalContainer>
        {comment()}
      </div>
    </Cell>
  );
}
