import { useHistory } from "react-router";
import { EditEvent } from "./EditEvent";

export function AddEventPage() {
  const history = useHistory();

  const onSave = () => {
    history.push("/events");
  };

  return (
    <div className="page">
      <EditEvent onSave={onSave} />
    </div>
  );
}
