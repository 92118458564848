import { ReactNode } from "react";

interface Props {
  isShowing: boolean;
  setShowing: (value: boolean) => void;
  didCancel?: () => void;
  children: ReactNode;
}

export function Modal({ isShowing, setShowing, didCancel, children }: Props) {
  function onCancelled() {
    didCancel?.();
    setShowing(false);
  }

  const containingClass = isShowing ? "modal-showing" : "modal-hidden";

  return (
    <div className={"modal-backdrop " + containingClass} onClick={onCancelled}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <button className="modal-cancel" onClick={onCancelled}>
          Cancel
        </button>
        {children}
      </div>
    </div>
  );
}
