export function Landing() {
  return (
    <div className="page">
      <h1>Welcome to Flugelhorn</h1>
      <p>
        For those times when you might text your friends "about to go do
        something fun, you up?", Flugelhorn can help. Send out a quick event to
        let people know you're about to hop in a game, go to the park, or
        anything else.
      </p>
    </div>
  );
}
