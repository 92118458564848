import { useContext, useEffect, useState } from "react";
import { Context } from "../Utility/Store";
import { Event } from "../Models/Event";

import "react-datepicker/dist/react-datepicker.css";
import { EditEvent } from "./EditEvent";
import { useHistory, useParams } from "react-router";
import { getEventDetails, handleResult } from "../Utility/Services";
import { DispatchedResultHandler } from "../Utility/Reducer";

interface EventDetailsParams {
  eventId: string;
}

export function EventDetailsPage() {
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const { eventId } = useParams<EventDetailsParams>();
  const [event, setEvent] = useState<Event>();

  useEffect(() => {
    async function fetch() {
      const result = await getEventDetails(state, eventId);
      const handler = new DispatchedResultHandler<Event>(dispatch, (event) => {
        setEvent(event);
      });
      handleResult(result, handler);
    }
    fetch();
  }, []);

  const handleSave = () => {
    history.push("/events");
  };

  const content = () => {
    if (event) {
      return <EditEvent event={event} onSave={handleSave} />;
    } else {
      return <h3>This is awkward</h3>;
    }
  };

  return <div className="page">{content()}</div>;
}
